import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import Image1 from "../../assets/images/Blogs/desserts-shop-600x480.jpeg";
import { Helmet } from "react-helmet";
import Footer from "../Common/Footer";
import swal from "sweetalert";
import axios from "axios";
import moment from "moment";
import Loader from "../Common/Loader";
import { Link, useLocation, useParams } from "react-router-dom";
import commonMenu from "../../assets/images/Common-menu-.pdf";
import rollzMenu from "../../assets/images/Menu-Without-pint-and-cake.pdf";
import { config } from "../../config";
const BlogDetail = () => {
  const allLocation = useLocation();
  useEffect(() => {
    onBlog();
    onBlogMain();
  }, [allLocation]);
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [data, setdata] = useState([]);
  const [blog, setBlog] = useState([]);
  const onBlog = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", 1);
    formData.append("page", "blog");
    try {
      const response = await axios({
        method: "get",
        url: `${config.apiUrl}/blog/${params.slug}`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setdata(response.data.data);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  const onBlogMain = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", 1);
    formData.append("page", "blog");
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/blogs`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setBlog(response.data.data);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [price, setPrice] = useState("");
  const [message, setMessage] = useState("");
  const onAddLead = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("firstname", first_name);
    formData.append("lastname", last_name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("country", country);
    formData.append("province", province);
    formData.append("purpose", message);
    formData.append("price", price);
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/add_lead`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        swal({
          icon: "success",
          text: "Your Message send Successfully",
        }).then((data) => {
          setLoading(false);
          setFirstName("");
          setLastName("");
          setPhone("");
          setEmail("");
          setCountry("");
          setPrice("");
          setMessage("");
        });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const [location_name, setLocationName] = useState("");
  const [person_name, setPersonName] = useState("");
  const onLocationMenu = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("location_name", location_name);
    formData.append("person_name", person_name);
    formData.append("type", 1);
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/download_menu`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        swal({
          icon: "success",
          text: "Your Menu Downloaded Successfully",
        }).then((data) => {
          setLoading(false);
          setLocationName("");
          setPersonName("");
          if (
            location_name == "Ajax" ||
            location_name == "Mississauga" ||
            location_name == "Scarborough" ||
            location_name == "Collingwood" ||
            location_name == "Edmonton" ||
            location_name == "Vaughan" ||
            location_name == "Kitchener" ||
            location_name == "Peterborough" ||
            location_name == "Halifax" ||
            location_name == "NorthParkDriveBrampton"
          ) {
            const anchor = document.createElement("a");
            const fileUrl = rollzMenu;
            anchor.href = fileUrl;

            anchor.download = "Menu-Without-pint-and-cake.pdf";
            document.body.appendChild(anchor);

            anchor.click();

            document.body.removeChild(anchor);
          } else {
            const anchor = document.createElement("a");
            const fileUrl = commonMenu;
            anchor.href = fileUrl;

            anchor.download = "Common-menu-.pdf";
            document.body.appendChild(anchor);

            anchor.click();

            document.body.removeChild(anchor);
          }
        });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const locationToPdfUrl = {
    Ajax: rollzMenu,
    Mississauga: rollzMenu,
    Scarborough: rollzMenu,
    Collingwood: rollzMenu,
    Regina: commonMenu,
    Edmonton: rollzMenu,
    Guelph: commonMenu,
    Vaughan: rollzMenu,
    Windsor: commonMenu,
    Kitchener: rollzMenu,
    Brantford: commonMenu,
    Peterborough: rollzMenu,
    Halifax: rollzMenu,
    NorthParkDriveBrampton: rollzMenu,
  };
  const [post_id, setPostId] = useState("");
  const [comment, setComment] = useState("");
  const [website, setWebsite] = useState("");
  const [blog_email, setBlogEmail] = useState("");
  const [name, setName] = useState("");
  const onBlogComment = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("post_id", params.slug);
    formData.append("comment", comment);
    formData.append("email", blog_email);
    formData.append("website", website);
    formData.append("name", name);
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/add_comment`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        swal({
          icon: "success",
          text: "Your Blog Comment Send Successfully",
        }).then((data) => {
          setLoading(false);
          setComment("");
          setWebsite("");
          setName("");
          setBlogEmail("");
        });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div>
      {isLoading && <Loader />}
      <Helmet>
        <title>{data.post_title}</title>
        <meta name="description" content={data.meta_description} />
      </Helmet>

      <Navbar />
      <Banner />
      <div className="content-wrapper">
        <div className="container">
          <div className="contain-padi">
            <div className="row">
              <div className="col-sm-8 overflow-hidden pe-md-4">
                <div>
                  <div className="d-flex mb-5" id="column-xs-blog-detail">
                    <div id="blog-detail">
                      <p className="date_section">
                        <h1> {moment(data.date).format("DD")}</h1>
                        <div> {moment(data.date).format("MMM yy")}</div>
                      </p>
                    </div>
                    <div id="blog-detail-content">
                      <div className="bg-white">
                        <div>
                          <img
                            src={data.post_img}
                            alt="Rollz Ice Cream Blog"
                            className="w-100"
                          />
                        </div>
                        <div className="blog-content">
                          <div>
                            <h1>{data.post_title}</h1>
                          </div>
                          <div className="img-detail">

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data.post_content,
                                }}
                              />
                          </div>
                        </div>
                      </div>
                      <div className=" mt-4">
                        <Link
                          to="rollzicecream-react/blog"
                          className="btn-read-more"
                          style={{ textDecoration: "none" }}
                        >
                          <i className="bi bi-chevron-left me-3"></i>Previous
                          Blog
                        </Link>
                      </div>
                      <div className="comment-box mt-5">
                        <h3>Leave a Reply </h3>
                        <p className="note">
                          Your email address will not be published. Required
                          fields are marked *
                        </p>
                        <form onSubmit={onBlogComment} className="mt-4">
                          <div className="row">
                            <div className="col-sm-6">
                              <input
                                className="form-rollz mt-md-0"
                                placeholder="Enter Name"
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-sm-6">
                              <input
                                className="form-rollz mt-md-0"
                                placeholder="Enter Email Address"
                                type="email"
                                value={blog_email}
                                onChange={(e) => setBlogEmail(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-sm-6">
                              <input
                                className="form-rollz"
                                placeholder="Website"
                                type="text"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-sm-12">
                              <textarea
                                className="form-rollz"
                                placeholder="Enter Comment"
                                type="email"
                                rows="6"
                                onChange={(e) => setComment(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="mt-4">
                            <button className="btn btn-submit">
                              Post Comment
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 ps-4">
                <div>
                  <div className="bd-recent-post">
                    <div>
                      <h2>Recent Posts</h2>
                    </div>
                    {blog.slice(0, 5).map((obj, i) => {
                      return (
                        <div className="row sect-post" key={i}>
                          <div className="col-3 pl-0">
                            <Link to={`/blog-detail/${obj.slug}`} style={{ color: "#797979", textDecoration: "none" }} >
                              <img
                                alt="Blog"
                                src={obj.post_img}
                                className="w-100"
                              />
                            </Link>
                          </div>
                          <div className="col-9">
                            <h5>
                              <Link to={`/blog-detail/${obj.slug}`} style={{ color: "#797979", textDecoration: "none" }} >{obj.post_title}</Link></h5>
                            <p>{moment(obj.date).format("DD MMM yy")} </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-5">
                    <div className="cta-download">
                      <form onSubmit={onLocationMenu}>
                        <div className="align-items-center">
                          <div className="pading-10">
                            <h5>Download Location wise Menu</h5>
                          </div>
                          <div className="pading-10">
                            <select
                              className="form-rollz bg-white mb-0"
                              name="location"
                              value={location_name}
                              onChange={(e) => setLocationName(e.target.value)}
                              required
                            >
                              <option value=''>All Location </option>
                              {Object.keys(locationToPdfUrl).map((location) => (
                                <option key={location} value={location}>
                                  {location}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="pading-10">
                            <input
                              className="form-rollz mb-0"
                              placeholder="Enter Name"
                              type="text"
                              value={person_name}
                              onChange={(e) => setPersonName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="pading-10">
                            <div className="text-center">
                              <button className="download-menu">
                                Download Menu
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="mt-5">
                    <img src={Image1} className="w-100" alt="Rollz Ice Cream" />
                  </div>
                  <div className="cta-download mt-5">
                    <div className="franchise-head">
                      <h3>FIND OUT MORE ABOUT OUR FRANCHISE OPPORTUNITIES</h3>
                      {/* <div className="card-bottom-line"></div> */}
                    </div>
                    <div className="form-franchise">
                      <form onSubmit={onAddLead}>
                        <div className="row">
                          <div className="col-12">
                            <input
                              className="form-rollz mt-md-0"
                              placeholder="Enter First Name"
                              type="text"
                              value={first_name}
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <input
                              className="form-rollz"
                              placeholder="Enter Last Name"
                              type="text"
                              value={last_name}
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <input
                              className="form-rollz"
                              placeholder="Enter Phone Number"
                              type="text"
                              value={phone}
                              required
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                          <div className="col-12">
                            <input
                              className="form-rollz"
                              placeholder="Enter Email Address"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <select
                              className="form-rollz bg-white"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                              required
                            >
                              <option value="">Select Country</option>
                              <option value="1">Canada</option>
                            </select>
                          </div>
                          <div className="col-12">
                            <select
                              className="form-rollz bg-white"
                              value={province}
                              onChange={(e) => setProvince(e.target.value)}
                              required
                            >
                              <option value=''>
                                State/Province/Territory (required)
                              </option>
                              <option value="1">Alberta</option>
                              <option value="13">British Columbia</option>
                              <option value="3">Manitoba</option>
                              <option value="4">New Brunswick</option>
                              <option value="5">
                                Newfoundland and Labrador
                              </option>
                              <option value="6">Northwest Territories</option>
                              <option value="14">Nova Scotia</option>
                              <option value="7">Nunavut</option>
                              <option value="8">Ontario</option>
                              <option value="9">Prince Edward Island</option>
                              <option value="10">Quebec</option>
                              <option value="11">Saskatchewan</option>
                              <option value="12">Yukon</option>
                            </select>
                          </div>
                          <div className="col-12">
                            <select
                              className="form-rollz bg-white"
                              onChange={(e) => setPrice(e.target.value)}
                              value={price}
                            >
                              <option value="">Select Price</option>
                              <option value="$100K - $200K">
                                $100K – $200K
                              </option>
                              <option value="$201K - $350K">
                                $201K – $350K
                              </option>
                              <option value="$351K - $500K">
                                $351K – $500K
                              </option>
                              <option value="Over $500K">Over $500K</option>
                            </select>
                          </div>
                          <div className="col-12">
                            <textarea
                              className="form-rollz"
                              placeholder="Tell us about yourself and why you're ready to open your own Rollz Ice Cream franchise..."
                              rows="8"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                          </div>

                          <div className="text-center mt-4">
                            <button className="download-menu">
                              Send Your Message
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;
