import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import "../../assets/css/Franchise.css";
import Footer from "../Common/Footer";
import axios from "axios";
import Loader from "../Common/Loader";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import { config } from "../../config";

const Franchise = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [price, setPrice] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [address, setAddress] = useState('')
  const [postal_code, setPostalCode] = useState('')

  // state for errors messages
  const [errors, setErrors] = useState({
    first_name: '',
    phone: '',
    email: '',
    country: '',
    province: '',
    price: '',
    address: '',
    postal_code: '',
  });


  // form validations
  const validateForm = () => {
    const newErrors = {};

    if (!first_name) {
      newErrors.first_name = 'First name is required.';
    }

    if (!email) {
      newErrors.email = 'Email is required.';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = 'Invalid email format.';
    }

    if (!phone) {
      newErrors.phone = 'Phone number is required.';
    } else if (phone.length > 10 || phone.length < 10) {
      newErrors.phone = 'Phone number must be of 10 digits.';
    }

    if (!country) {
      newErrors.country = 'Please Select Country.';
    }

    if (!province) {
      newErrors.province = 'Please Select Province.';
    }

    if (!price) {
      newErrors.price = 'Please Select Price.';
    }
    if (!address) {
      newErrors.address = 'Address is Required.';
    }
    if (!postal_code) {
      newErrors.postal_code = 'Postal Code is Required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onAddLead = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append("firstname", first_name);
      formData.append("lastname", last_name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("country", country);
      formData.append("province", province);
      formData.append("purpose", message);
      formData.append("price", price);
      formData.append("address", address);
      formData.append("pincode", postal_code);
      try {
        const response = await axios({
          method: "post",
          url: `${config.apiUrl}/add_lead`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          swal({
            icon: "success",
            text: "Your Message send Successfully",
          }).then((data) => {
            setLoading(false);
            setFirstName("");
            setLastName("");
            setPhone("");
            setEmail("");
            setCountry("");
            setPrice("");
            setMessage("");
            setAddress('')
            setPostalCode('')
          });
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    onFranchise();
  }, []);

  const onFranchise = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", localStorage.getItem("location"));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/franchise`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setdata(response.data.data);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };


  return (
    <div>
      <Helmet>
        <title>Own Top Ice Cream Franchise in Canada | Rollz Ice Cream</title>
        <meta
          name="description"
          content="Embark on your journey to sweet success by owning a Rollz Ice Cream Franchise across Canada. Join this delectable business opportunity today!"
        />
        <meta
          property="og:title"
          content="Own Top Ice Cream Franchise in Canada | Rollz Ice Cream"
        />
        <meta
          property="og:description"
          content="Embark on your journey to sweet success by owning a Rollz Ice Cream Franchise across Canada. Join this delectable business opportunity today!"
        />
      </Helmet>
      {isLoading && <Loader />}
      <Navbar />
      <Banner />

      <div className="content-wrapper">
        <div className="container">
          <div className="contain-padi">
            <div className="franchise-head">
              <h1>Franchise</h1>
              <div className="border-head-bottom"></div>
            </div>
            <div>
              {data.map((item, index) => {
                if (index % 2 != 0) {
                  return (
                    <div className="Card card-xs" key={index}>
                      <div className="row">
                        <div className="col-sm-6 order-xs-1">
                          <div className="side-img">
                            <img src={item.post_img} alt="Rollz Ice Cream" />
                            {item.post_img2 != null ? (
                              <img src={item.post_img2} alt="Rollz Ice Cream" className="mt-4" />
                            ) : null}
                          </div>
                        </div>
                        <div className="col-sm-6 side-second order-xs-2">
                          <div className="card-head">
                            <h1>{item.post_title}</h1>
                            <div className="card-bottom-line"></div>
                          </div>
                          <div className="card-text">
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.post_content,
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="Card card-xs" key={index}>
                      <div className="row">
                        <div className="col-sm-6 side-second order-xs-2">
                          <div className="card-head">
                            <h1>{item.post_title}</h1>
                            <div className="card-bottom-line"></div>
                          </div>
                          <div className="card-text">
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.post_content,
                                }}
                              />
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-6 order-xs-1">
                          <div className="side-img">
                            <img src={item.post_img} alt="Rollz Ice Cream" />
                            {item.post_img2 != null ? (
                              <img src={item.post_img2} alt="Rollz Ice Cream" className="mt-2" />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              <div className="Card card-xs">
                <div className=" ">
                  <div className="">
                    <div className="form-head">
                      <h3>FIND OUT MORE ABOUT OUR FRANCHISE OPPORTUNITIES</h3>
                      <div className="card-bottom-line"></div>
                    </div>
                    <div className="form-franchise">
                      <form onSubmit={onAddLead}>
                        <div className="row">
                          <div className="col-sm-6">
                            <input
                              className="form-rollz mt-md-1"
                              placeholder="Enter First Name"
                              type="text"
                              value={first_name}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                            {
                              errors.first_name && <span className="text-danger">{errors.first_name}</span>
                            }
                          </div>
                          <div className="col-sm-6">
                            <input
                              className="form-rollz mt-md-1"
                              placeholder="Enter Last Name"
                              type="text"
                              value={last_name}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                          <div className="col-sm-6">
                            <input
                              className="form-rollz"
                              placeholder="Enter Phone Number"
                              type="text"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              maxLength={12}
                              onKeyPress={(event) => {
                                if (
                                  !/[0-9.]/.test(event.key)
                                ) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {
                              errors.phone && <span className="text-danger">{errors.phone}</span>
                            }
                          </div>
                          <div className="col-sm-6">
                            <input
                              className="form-rollz"
                              placeholder="Enter Email Address"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {
                              errors.email && <span className="text-danger">{errors.email}</span>
                            }
                          </div>
                          <div className="col-sm-6">
                            <select
                              className="form-rollz bg-white"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            >
                              <option value="">Select Country</option>
                              <option value="1">Canada</option>
                            </select>
                            {
                              errors.country && <span className="text-danger">{errors.country}</span>
                            }
                          </div>
                          <div className="col-sm-6">
                            <select
                              className="form-rollz bg-white"
                              value={province}
                              onChange={(e) => setProvince(e.target.value)}
                            >
                              <option>
                                State/Province/Territory (required)
                              </option>
                              <option value="1">Alberta</option>
                              <option value="13">British Columbia</option>
                              <option value="3">Manitoba</option>
                              <option value="4">New Brunswick</option>
                              <option value="5">
                                Newfoundland and Labrador
                              </option>
                              <option value="6">Northwest Territories</option>
                              <option value="14">Nova Scotia</option>
                              <option value="7">Nunavut</option>
                              <option value="8">Ontario</option>
                              <option value="9">Prince Edward Island</option>
                              <option value="10">Quebec</option>
                              <option value="11">Saskatchewan</option>
                              <option value="12">Yukon</option>
                            </select>
                            {
                              errors.province && <span className="text-danger">{errors.province}</span>
                            }
                          </div>
                          <div className="col-sm-12">
                            <input
                              className="form-rollz "
                              placeholder="Enter Address"
                              type="text"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                            {
                              errors.address && <span className="text-danger">{errors.address}</span>
                            }
                          </div>
                          <div className="col-sm-6">
                            <input
                              className="form-rollz "
                              placeholder="Enter Postal Code"
                              type="text"
                              value={postal_code}
                              onChange={(e) => setPostalCode(e.target.value)}
                            />
                            {
                              errors.postal_code && <span className="text-danger">{errors.postal_code}</span>
                            }
                          </div>
                          <div className="col-sm-6">
                            <select
                              className="form-rollz bg-white"
                              onChange={(e) => setPrice(e.target.value)}
                              value={price}
                            >
                              <option value="">Select Price</option>
                              <option value="$100K - $200K">
                                $100K – $200K
                              </option>
                              <option value="$201K - $350K">
                                $201K – $350K
                              </option>
                              <option value="$351K - $500K">
                                $351K – $500K
                              </option>
                              <option value="Over $500K">Over $500K</option>
                            </select>
                            {
                              errors.price && <span className="text-danger">{errors.price}</span>
                            }
                          </div>
                          <div className="col-12">
                            <textarea
                              className="form-rollz"
                              placeholder="Tell us about yourself and why you're ready to open your own Rollz Ice Cream franchise..."
                              rows="8"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                          </div>

                          <div className="text-center mt-5">
                            <button className="btn btn-submit">
                              Send Your Message
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Franchise;
