import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Common/Navbar";
import "../../assets/css/Home.css";
import SliderSection from "../Common/Slider";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import VideoImg from "../../assets/images/rollz-video.19c5a376a0a38d3778ae.jpg";
import VideoImg2 from "../../assets/images/video-2.5ec174f6a6fb41ebbb8a.jpg";
import VideoImg3 from "../../assets/images/Waffle.png";
import Footer from "../Common/Footer";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Common/Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { config } from "../../config";

const Index = ({ call, location_name_change, setLocationNameChange }) => {
  const sliderBlogRef = useRef();
  const sliderLocationRef = useRef()
  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
  };
  const GallerySettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("location") != null) {
      onBannerSection();
    }
  }, [call, location.pathname]);

  useEffect(() => {
    // if (localStorage.getItem('change') == true) {
    // if (location.pathname === ") {

    switch (localStorage.getItem('location_name')) {
      case "Ajax": navigate("/ajax"); break;
      case "Mississauga": navigate("/mississauga"); break;
      case "Scarborough": navigate("/scarborough"); break;
      case "Collingwood": navigate("/collingwood"); break;
      case "Regina": navigate("/regina"); break;
      case "Edmonton": navigate("/edmonton"); break;
      case "Guelph": navigate("/guelph"); break;
      case "Vaughan": navigate("/vaughan"); break;
      case "Windsor": navigate("/windsor"); break;
      case "Kitchener": navigate("/kitchener"); break;
      case "Brantford": navigate("/brantford"); break;
      case "Calgary (Coming Soon)": navigate("/calgary"); break;
      case "North Park Drive, Brampton": navigate("/north-park-drive-brampton"); break;
      case "Halifax": navigate("/halifax"); break;
      case "Peterborough": navigate("/peterborough"); break;
      case "Waterloo": navigate("/waterloo"); break;
      case "Lebovic, Scarborough": navigate("/lebovic-scarborough"); break;
      case "Meadows, Edmonton": navigate("/meadows"); break;
      case "Kingston": navigate("/kingston"); break;

      default: navigate("/")
      // }
      // }

      // if(location.pathname === "/"){
      // navigate(`/${localStorage.getItem('location_name')}`)
      // navigate(`/${location.pathname}` + `${localStorage.getItem('location_name')}`)
      // }
    }
  }, [location_name_change])

  // https://api.rollzfranchise.com/aapi/
  // https://ztpl.net/rollz_api/aapi/


  const [isLoading, setLoading] = useState(false);
  const [videoModal, setVideoModal] = useState("");
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [image3, setImage3] = useState([]);
  const [image4, setImage4] = useState([]);
  const [video1, setVideo1] = useState([]);
  const [video2, setVideo2] = useState([]);
  const [video3, setVideo3] = useState([]);
  const [gallary, setGallary] = useState([]);
  const [whyRollzDesc1, setWhyRollzdecs1] = useState([]);
  const [whyRollzDesc2, setWhyRollzdecs2] = useState([]);
  const [whyRollzDesc3, setWhyRollzdecs3] = useState([]);
  const [whyRollzDesc4, setWhyRollzdecs4] = useState([]);
  const [whyRollzDesc5, setWhyRollzdecs5] = useState([]);
  const [whyRollzDesc6, setWhyRollzdecs6] = useState([]);
  const [data, setData] = useState([]);
  const [locationData, setLocationData] = useState([]);

  const [datas, setDatas] = useState([]);
  const [sliderTitle, setSliderTitle] = useState([]);
  const [sliderDesc, setSliderDesc] = useState([]);

  const onBannerSection = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", localStorage.getItem("location"));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/homepage`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setImage1(response.data.whats_rolling.image1);
        setImage2(response.data.whats_rolling.image2);
        setImage3(response.data.whats_rolling.image3);
        setImage4(response.data.whats_rolling.image4);
        setVideo1(response.data.whats_rolling.video1);
        setVideo2(response.data.whats_rolling.video2);
        setVideo3(response.data.whats_rolling.video3);
        setGallary(response.data.second_slider);
        setWhyRollzdecs1(response.data.why_rollz.description1);
        setWhyRollzdecs2(response.data.why_rollz.description2);
        setWhyRollzdecs3(response.data.why_rollz.description3);
        setWhyRollzdecs4(response.data.why_rollz.description4);
        setWhyRollzdecs5(response.data.why_rollz.description5);
        setWhyRollzdecs6(response.data.why_rollz.description6);
        setDatas(response.data.slider.img);
        setSliderTitle(response.data.slider.title);
        setSliderDesc(response.data.slider.description);
        setData(response.data.whats_rolling.reviews);
        setLocationData(response.data.location);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  const changeVideo = (video) => {
    setVideoModal(video);
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");



  // state for errors messages
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
  });


  // form validations
  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'First name is required.';
    }

    if (!email) {
      newErrors.email = 'Email is required.';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = 'Invalid email format.';
    }

    if (!phone) {
      newErrors.phone = 'Phone number is required.';
    } else if (phone.length > 10 || phone.length < 10) {
      newErrors.phone = 'Phone number must be of 10 digits.';
    }

    if (!subject) {
      newErrors.subject = 'Subject is required.';
    }

    if (!message) {
      newErrors.message = 'Message is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const onContactUs = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("message", message);
      formData.append("subject", message);
      try {
        const response = await axios({
          method: "post",
          url: `${config.apiUrl}/contact_us`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          swal({
            icon: "success",
            text: "Your Message send Successfully",
          }).then((data) => {
            setLoading(false);
            setName("");
            setPhone("");
            setEmail("");
            setSubject("");
            setMessage("");
          });
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const iframeRef = useRef(null);

  const stopVideo = () => {
    setVideoModal('')
  };

  return (
    <div>

      {isLoading && <Loader />}
      <Helmet>
        <title>Rolled Ice Cream- Canada&#039;s Best Desserts Shop Near Me</title>
        <meta name="description" content="Rollz stands as the best Rolled Ice Cream and Delicious Desserts shop in Toronto. We take pride in serving cheesecake, waffles, ice cream cake, milkshakes, etc." />
        <meta
          property="og:title"
          content="Enjoy Yummy Ice Cream And Desserts Blog | Rollz Ice Cream"
        />
        <meta
          property="og:description"
          content="Discover a treasure trove of blog content covering ice cream recipe, what is and how to make cheesecake, and much more. Feel free to explore more."
        />
      </Helmet>
      <Navbar call={call} setLocationNameChange={setLocationNameChange} />
      <SliderSection
        data={datas}
        sliderTitle={sliderTitle}
        sliderDesc={sliderDesc}
      />
      <div className="bg-purple">
        <div className="container">
          <div className="contain-padi">
            <div className="bd-icon-cake">
            </div>
            <div className="pt-3">
              <div className="text-center">
                <i className="bi bi-cake bd-cake-icon"></i>
                <div className="slider-text">
                  <h1 className="mb-5">What's Rolling</h1>
                </div>
                <div className="pb-60">
                  <div className="d-flex flext-xs-column">
                    <div className="position-relative">
                      <img src={image1} className="img-xs-fluid" alt="Rollz Ice Cream" />
                      <div className="rollz-name">
                        <p>
                          ROLLZ
                          <span>Ice Cream</span>
                        </p>
                      </div>
                    </div>
                    <div className="bd-second-roll">
                      <div>
                        <img src={image2} alt="Rollz Ice Cream" />
                      </div>
                      <div className="testtimonial-img" id="testtimonial-img-1">
                        <div className="w-100">
                          <h4>Customer Reviews</h4>
                          <p>Sourced from Google Reviews</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-50 w-xs-100" id="w-xs-100-0">
                      <div className="d-flex flext-xs-column">
                        <div className="w-50 w-xs-100" id="w-xs-100-1">
                          <img src={image3} className="w-xs-100" alt="Rollz Ice Cream" />
                        </div>
                        <div className="bg-white w-50 w-xs-100" id="w-xs-100-2">
                          <img src={image4} className="w-xs-100" alt="Rollz Ice Cream" />
                        </div>
                        <div className="testtimonial-img testtimonial-img-sec d-none">
                          <div className="w-100">
                            <h4>Customer Reviews</h4>
                            <p>Sourced from Google Reviews</p>
                          </div>
                        </div>
                      </div>
                      <div className="bd-slider-testimonial">
                        <Slider ref={sliderBlogRef} {...blogsSettings}>
                          {data?.map((item, index) => {
                            return (
                              <div className="text-left w-100" key={index}>
                                <div className="testimonial-name">
                                  <i className="bi bi-quote"></i>
                                  <strong>{item.name}</strong>
                                </div>
                                <p className="testimonial-text">
                                  {item.comment}
                                </p>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pad-section">
                  <div className="row justify-content-center">
                    <div className="col-4 d-flex justify-content-center col-xs-12">

                      <div className="bd-video-sect">
                        <img
                          src={VideoImg}
                          alt="Rollz Ice Cream"
                          height="400"
                          width="340"
                          className=""
                          style={{ objectFit: "cover", opacity: 0.4 }}
                        />
                        <div
                          onClick={() => changeVideo(video1)}
                          className="play-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <i className="bi bi-play-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-center col-xs-12">
                      <div className="bd-video-sect">
                        <img
                          src={VideoImg2}
                          alt="Rollz Ice Cream"
                          height="400"
                          className=""
                          width="340"
                          style={{ objectFit: "cover", opacity: 0.4 }}
                        />
                        <div
                          onClick={() => changeVideo(video2)}
                          className="play-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <i className="bi bi-play-circle-fill"></i>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-4 d-flex justify-content-center col-xs-12">

                      <div className="bd-video-sect">
                        <img
                          src={VideoImg3}
                          alt="Rollz Ice Cream"
                          height="400"
                          className=""
                          width="340"
                          style={{ objectFit: "cover", opacity: 0.4 }}
                        />
                        <div
                          onClick={() => changeVideo(video3)}
                          className="play-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          <i className="bi bi-play-circle-fill"></i>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Location Section */}
      <div className="bg-light-gray pb-5">
        <div className="roll-location mb-5">
          <div className="container text-center">
            <i className="bi bi-cake bd-cake-icon"></i>
            <div className="slider-text mt-3">
              <h1 className="mb-3 text-uppercase">Where to find us</h1>
            </div>
          </div>
        </div>

        <div className="container ">
          <div>
            <div className="row position-relative ">
              <p
                className="style-btn-slider bg-white col-sm-5 ms-3 d-flex align-items-center slick-left justify-content-center"
                id="slick-left"
                onClick={() => sliderLocationRef.current.slickPrev()}
                style={{ cursor: "pointer", left: "-80px", top: "137px" }}
              >
                <i className="bi bi-chevron-left"></i>
              </p>
              <p
                style={{ cursor: "pointer", right: "-65px", top: "137px" }}
                className="style-btn-slider bg-white col-sm-5 slick-right d-flex align-items-center justify-content-center"
                id="slick-right"
                onClick={() => sliderLocationRef.current.slickNext()}
              >
                <i className="bi bi-chevron-right"></i>
              </p>
              <Slider ref={sliderLocationRef} {...GallerySettings}>
                {locationData.map((obj, index) => {
                  return (
                    <div className="col-lg-4 px-3" key={index}>
                      <div
                        className={`text-center mt-4 bg-white p-4 ${index == 0
                          ? "green-messes"
                          : index == 1
                            ? "yellow-messes"
                            : index == 2
                              ? "pink-messes"
                              : index == 3
                                ? "green-messes"
                                : index == 4
                                  ? "yellow-messes"
                                  : index == 5
                                    ? "pink-messes"
                                    : index == 6
                                      ? "green-messes"
                                      : index == 7
                                        ? "yellow-messes"
                                        : "pink-messes"
                          }`}
                      >
                        <div className="messes">
                          <div className="messes-show"></div>
                          <div
                            className={`messes-round-wrap ${index == 0
                              ? "green-option"
                              : index == 1
                                ? "yellow-option"
                                : index == 2
                                  ? "pink-option"
                                  : index == 3
                                    ? "green-option"
                                    : index == 4
                                      ? "yellow-option"
                                      : index == 5
                                        ? "pink-option"
                                        : index == 6
                                          ? "green-option"
                                          : index == 7
                                            ? "yellow-option"
                                            : "pink-option"
                              }`}
                          >
                            <img src={obj.image} className="d-inline" alt="Rollz Ice Cream" />
                          </div>
                        </div>
                        <h2 className="messes-title fs-5">{obj.location_name}</h2>
                        <div className="messes-title-border"></div>
                        <p className="messes-desc">{obj.location_address}</p>
                      </div>
                    </div>
                  );
                })}
              </Slider>

              <div className="mt-5 text-center mb-4">
                <Link to="/locations" className="btn btn-submit w-auto px-4">View All Locations</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why us Section */}

      <div>
        <div className="bg-why">
          <div className="triangle-top"></div>
          <div className="container text-center">
            <i className="bi bi-cake bd-cake-icon"></i>
            <div className="slider-text mt-3">
              <h1 className="mb-3 text-uppercase">Why Rollz Ice Cream</h1>
            </div>
          </div>
          <div className="triangle-bottom"></div>
        </div>
        <div className="bg-light-gray pt-5 pb-5 px-xs-3">
          <div className="container ">
            <div className="row justify-space-between">
              <div className="col-lg-5">
                <div className="rollz-ice-cream-info">
                  <div className="d-flex">
                    <div className="count">1</div>
                    <div>
                      <p className="p-xs-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: whyRollzDesc1,
                          }}
                        />
                      </p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="count">2</div>
                    <div>
                      <p className="p-xs-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: whyRollzDesc3,
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="count">3</div>
                    <div>
                      <p className="p-xs-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: whyRollzDesc5,
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 text-right text-xs-left">
                <div className="rollz-ice-cream-info right-side">
                  <div className="d-flex">
                    <div className="order-xs-2">
                      <p className="p-xs-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: whyRollzDesc2,
                          }}
                        />
                      </p>
                    </div>
                    <div className="count order-xs-1">4</div>
                  </div>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "end" }}
                    id="jc-xs-fs"
                  >
                    <div className="order-xs-2">
                      <p className="p-xs-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: whyRollzDesc4,
                          }}
                        />
                      </p>
                    </div>
                    <div className="count order-xs-1">5</div>
                  </div>
                  <div className="d-flex">
                    <div className="order-xs-2">
                      <p className="p-xs-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: whyRollzDesc6,
                          }}
                        />
                      </p>
                    </div>
                    <div className="count order-xs-1">6</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Gallery Section */}

      <div className="bg-why">
        <div className="triangle-top"></div>
        <div className="banner-slick mb-5">
          <div
            className="container"
            style={{ padding: "0px 40px" }}
            id="p-xs-0"
          >
            <div className="row gallery-banner">
              <p
                className="style-btn-slider col-sm-5 me-3 slick-left"
                id="slick-left"
                onClick={() => sliderBlogRef.current.slickPrev()}
                style={{ cursor: "pointer", left: "-80px", top: "137px" }}
              >
                <i className="bi bi-chevron-left"></i>
              </p>
              <p
                style={{ cursor: "pointer", right: "-65px", top: "137px" }}
                className="style-btn-slider col-sm-5 slick-right"
                id="slick-right"
                onClick={() => sliderBlogRef.current.slickNext()}
              >
                <i className="bi bi-chevron-right"></i>
              </p>

              <Slider ref={sliderBlogRef} {...GallerySettings}>
                {gallary.map((item, index) => {
                  return (
                    <div className="col-sm-4 col-12 pe-3" key={index}>
                      <img src={item.image} id="slick-slide-2-img" alt="Rollz Ice Cream" />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
        <div className="triangle-bottom"></div>
      </div>

      <div className="pad-section bg-light-gray">
        <div className="container">
          <div className="text-center">
            <i className="bi bi-cake bd-cake-icon purple"></i>
            <div className="slider-text mt-3">
              <h1 className="mb-3 text-uppercase purple">Contact Us</h1>
            </div>
          </div>

          <div className="mt-4">
            <div>
              <form onSubmit={onContactUs}>
                <div className="row">
                  <div className="col-sm-6">
                    <input
                      className="form-rollz mt-md-0"
                      placeholder="Enter Name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {
                      errors.name && <span className="text-danger">{errors.name}</span>
                    }
                  </div>
                  <div className="col-sm-6">
                    <input
                      className="form-rollz mt-md-0"
                      placeholder="Enter Email"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {
                      errors.email && <span className="text-danger">{errors.email}</span>
                    }
                  </div>
                  <div className="col-sm-6">
                    <input
                      className="form-rollz"
                      placeholder="Enter Phone Number"
                      value={phone}
                      type="text"
                      onKeyPress={(event) => {
                        if (
                          !/[0-9.]/.test(event.key)
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {
                      errors.phone && <span className="text-danger">{errors.phone}</span>
                    }
                  </div>
                  <div className="col-sm-6">
                    <input
                      className="form-rollz"
                      placeholder="Enter Subject"
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    {
                      errors.subject && <span className="text-danger">{errors.subject}</span>
                    }
                  </div>
                  <div className="col-sm-12">
                    <textarea
                      className="form-rollz"
                      placeholder="Enter Your Message"
                      type="text"
                      rows="8"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    {
                      errors.message && <span className="text-danger">{errors.message}</span>
                    }
                  </div>
                </div>
                <div className="text-center mt-5">
                  <button className="btn btn-submit">Send Your Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered video-modal video-pop modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title video-title" id="exampleModalLabel">
                Customer Review
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={stopVideo}
              ></button>
            </div>
            <div className="modal-body">
              <iframe
                ref={iframeRef}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "400px",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
                title="YouTube video player"
                src={videoModal}
                allowFullScreen="allowfullscreen"
                data-mce-fragment="1"
              ></iframe>

              <div className="review-text text-center">
                <p>
                  Indulge your taste buds in a delightful journey with{" "}
                  <span>Rollz Ice Cream!</span> Our unique hand-rolled ice cream
                  creations are a sensory delight, blending premium ingredients
                  and artistic flair. From rich chocolatey indulgence to fruity
                  freshness, every roll is a masterpiece.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
